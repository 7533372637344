import styled from "styled-components";
import { Link as LinkDom } from "react-router-dom";

const BUTTON_STYLE = (theme: any) => ({
  cursor: "pointer",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "18px",
  background: theme.colors.text.gradient,
  boxShadow: "0px 6px 24px rgba(38, 89, 243, 0.32)",
  borderRadius: "6px",
  color: theme.colors.text.white,
  alignItems: "center",
  border: "none",
  padding: "19px",
  maxWidth: "294px",
  width: "100%",
  transition: "box-shadow 0.2s",
  position: "relative",
  display: "inline-block",
  fontFamily: "inherit",

  [theme.breakpoint.down.lg]: { maxWidth: "100%" },

  "&:hover": {
    boxShadow: "0px 6px 24px rgba(38, 89, 243, 0.48)",
  },

  "&:focus": {
    outline: "none",

    "&::after": {
      content: '""',
      position: "absolute",
      top: "-4px",
      left: "-4px",
      width: "calc(100% + 4px)",
      height: "calc(100% + 4px)",
      border: `2px solid ${theme.colors.text.darkPurple}`,
      borderRadius: "10px",
      opacity: "0.3",
      transition: "0.1s",
    },
  },
});

//@ts-ignore
export const Button = styled("button")(({ theme }) => BUTTON_STYLE(theme));

//@ts-ignore
export const Link = styled(LinkDom)(({ theme }) => BUTTON_STYLE(theme));
