import React, { useRef, useState } from "react";
import { useEffect } from "react";
import {
  Section,
  Container,
  Breadcrumbs,
  Tree,
  Title,
  LoaderWrapper,
  FormError,
  FormInformation,
  Terms,
} from "./Dashboard.styled";
import { m } from "framer-motion";
import { LazyMotion, domAnimation } from "framer-motion";
import { Loader, Typography } from "../../shared";
import { Form } from "../../entities";
import { useAppSelector } from "../../store/hooks";
import { getBranch } from "../../store/reducers/branch";
import { shallowEqual } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export const toFixedTwo = (num: number) => {
  return num
    .toLocaleString("fr-FR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(/\s+/g, "")
    .replace(",", ".");
};

export const getComission = ({
  comission,
  sum,
}: {
  comission: number;
  sum: number;
}) => {
  return toFixedTwo(+((sum * comission) / 100));
};

export const isRequiredField = ({
  view,
  code_out,
  edit,
  min_length,
  max_length,
  required,
}: any) => {
  return (
    (view === "1" &&
      code_out !== null &&
      edit === "1" &&
      +min_length > 0 &&
      +max_length > 0) ||
    required
  );
};

const lettersReplacer = (str: string) => {
  return str.replace(/[^\d.-]/g, "");
};

export const MOTION_FADE_BACK_PROPS = {
  initial: "initial",
  animate: "animate",
  exit: "exit",
  name: "Fade Back",
  variants: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  },
  transition: {
    duration: 0.2,
  },
};

const DEFAULT_LOCATION = {
  name: 'Система "Расчёт" (ЕРИП)',
  code: "11000000000",
  di_type: "9191",
};

export const AMOUNT = "Сумма";
export const TOTAL_AMOUNT = "Сумма всего";
export const COMISSION = "Сумма вознаграждения сервиса";

const Layout = ({ className }: { className?: string }) => {
  const { service_no_erip, account_number } = useParams();
  const navigate = useNavigate();
  const branch = useAppSelector(getBranch, shallowEqual);
  const test = window.location.href.includes("?test");
  const [requestSent, setRequestSent] = useState(false);
  const [title, setTitle] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([DEFAULT_LOCATION]);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [tree, setTree] = useState<any>();
  const [form, setForm] = useState<any>();
  const stateRef = useRef({ sessionId: "" });
  const [widgetRequested, setWidgetRequested] = useState(false);
  const [normalizedNames, setNormalizedNames] = useState<{
    [key: string]: { default: string; normalized: string };
  }>({});

  const dataProcessing = (data: any) => {
    try {
      const { pay_record, error_text, response } = data;
      if (error_text || response) {
        setFormErrorMessage(error_text || response?.description);
      } else {
        if (Array.isArray(pay_record)) {
          setTree(pay_record);
        } else {
          if (pay_record.erip_session_id) {
            stateRef.current.sessionId = pay_record.erip_session_id;
          }

          const normalizedInputNames = pay_record.attr_records?.reduce(
            (acc: any, { name }: any) => ({
              ...acc,
              [name]: {
                normalized: name
                  .trim()
                  .replace(/\./g, "")
                  .split(/[\s,\t,\n]+/)
                  .join(" "),
                default: name,
              },
            }),
            {}
          );

          setNormalizedNames((prev) => ({ ...prev, ...normalizedInputNames }));

          setForm({
            ...pay_record,
            attr_records: [
              ...pay_record?.attr_records?.flatMap((item: any) =>
                item.view !== "0"
                  ? item.value?.includes("BYN")
                    ? [
                        {
                          ...item,
                          value: toFixedTwo(
                            +item.value
                              .replace("BYN", "")
                              .replace(/\s+/g, "")
                              .replace(",", ".")
                              .trim()
                          ),
                          badge: "BYN",
                        },
                      ]
                    : [item]
                  : []
              ),
              ...(pay_record?.summa && pay_record.view === "1"
                ? [
                    {
                      name: AMOUNT,
                      data_type: "R",
                      value: pay_record.summa,
                      view: "1",
                      edit: pay_record.edit,
                      badge: "BYN",
                      change: "0",
                      min: (pay_record.min && +pay_record.min) || 0.01,
                      max: (pay_record.max && +pay_record.max) || 999999999.99,
                      required: pay_record.get_pay_list_type === "1",
                    },
                  ]
                : []),
              ...(pay_record?.commission && pay_record.view === "1"
                ? [
                    {
                      name: COMISSION,
                      data_type: "R",
                      value: `${+getComission({
                        comission: +pay_record.commission,
                        sum: +pay_record.summa,
                      })}
                      `,
                      badge: "BYN",
                      edit: "0",
                      change: "0",
                      view: "1",
                    },
                  ]
                : []),
              ...(pay_record.total_amount && pay_record.view === "1"
                ? [
                    {
                      name: TOTAL_AMOUNT,
                      data_type: "R",
                      value: `${pay_record.total_amount || 0}`,
                      badge: "BYN",
                      edit: "0",
                      change: "0",
                      view: "1",
                    },
                  ]
                : []),
            ],
          });

          setTitle(pay_record.name);
        }
      }
    } catch (e) {}
  };

  const getFillForm = async () => {
    try {
      setRequestSent(true);
      const requestObject: any = {
        service_no_erip,
        test,
      };

      if (account_number) {
        requestObject.account_number = account_number;
      }
      const res = await fetch(
        `${process.env.REACT_APP_API}/beyag/komplat/v2/fill-form`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestObject),
        }
      );

      const data = await res.json();

      dataProcessing(data);

      setRequestSent(false);
    } catch (e) {
      setFormErrorMessage("Что-то пошло не так");
      setRequestSent(false);
    }
  };

  const getData = async (data: any) => {
    const res = await fetch(
      `${process.env.REACT_APP_API}/beyag/komplat/v2/get_pay_list`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        redirect: "follow",
      }
    );

    return await res.json();
  };

  const getTree = async ({ di_type, pay_code, ...props }: any) => {
    try {
      setRequestSent(true);
      setTree(null);
      setForm(null);
      setFormErrorMessage("");
      const data = await getData({
        version: "3",
        test,
        di_type,
        pay_code,
        ...(stateRef.current.sessionId
          ? { erip_session_id: stateRef.current.sessionId }
          : {}),
        ...props,
      });

      dataProcessing(data);

      setRequestSent(false);
    } catch (e: any) {
      if (e?.response) {
        setFormErrorMessage(e.response?.message || "Что-то пошло не так");
        setRequestSent(false);
      } else {
        navigate("/result/timeout");
      }
    }
  };

  useEffect(() => {
    if (service_no_erip) {
      getFillForm();
    } else if (branch.code && branch.di_type) {
      getTree({
        di_type: branch.di_type,
        pay_code: branch.code,
      });
    } else {
      getTree({
        di_type: DEFAULT_LOCATION.di_type,
        pay_code: DEFAULT_LOCATION.code,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTreeItemSelect = (
    _: React.MouseEvent,
    item: {
      name: string;
      code: string;
      di_type: string;
    }
  ) => {
    setBreadcrumbs((prev) => [...prev, item]);

    setTitle(item.name);
    getTree({ pay_code: item.code, di_type: item.di_type });
  };

  const handleBreadcrumbChange = (
    _: any,
    breadcrumb: { name: string; code: string; di_type: string },
    index: number
  ) => {
    stateRef.current.sessionId = "";
    getTree({ di_type: breadcrumb.di_type, pay_code: breadcrumb.code });
    setBreadcrumbs(
      index === 0 ? [DEFAULT_LOCATION] : breadcrumbs.slice(0, index + 1)
    );
    setTitle(index === 0 ? "" : breadcrumb.name);
  };

  const fieldMapping = (initialArray: any, formData: any) => {
    return (initialArray || [])
      .filter(({ code, code_out }: any) => code && code_out)
      .map((input: any) => {
        const { name, code, code_out, edit, value, change = "0" } = input;

        const normalizedValue = Object.values(normalizedNames).find(
          (item) => item.default === name
        );

        const res: any = {
          name,
          code,
          code_out,
          value: formData[normalizedValue?.normalized || name],
          edit,
          change,
        };

        edit === "1" && value !== res.value && (res.change = "1");

        return res;
      });
  };

  const handleSubmitForm = async (data: any) => {
    const lastBreadcrumb = breadcrumbs.at(-2);

    const pay_code = form.next_pay_code || form.code || lastBreadcrumb.code;
    const di_type = form.di_type || lastBreadcrumb.di_type;

    const request: any = {
      pay_code,
      di_type,
      attr_records: fieldMapping(form.attr_records, data),
    };

    if (form.get_pay_list_type === "1") {
      const sumField = data[AMOUNT];

      if (sumField) {
        const prevVal = form.attr_records.find(
          ({ name }: any) => name === AMOUNT
        )?.value;

        if (prevVal && +sumField !== +prevVal) {
          request.summa = sumField;
        }
      }

      getTree(request);
    } else {
      const public_key = process.env.REACT_APP_PUBLIC_KEY;
      const reqObj = {
        headers: {
          "X-Api-Version": "2",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${public_key}`,
        },
        method: "POST",
        body: JSON.stringify({
          checkout: {
            public_key,
            test,
            settings: {
              return_url: `${window.location.origin}/result/{token}`,
              auto_return: "0",
              language: "ru",
              style: {
                widget: {
                  buttonsColor: "#2659F3",
                },
              },
            },
            order: {
              currency: "BYN",
              amount: +lettersReplacer(data[TOTAL_AMOUNT]).replace(".", ""),
              description: form.name,
              additional_data: {
                komplat: {
                  pay_code,
                  di_type,
                  erip_session_id: stateRef.current.sessionId,
                  fine: form.fine,
                  pay_commission: lettersReplacer(data[COMISSION]),
                  summa: lettersReplacer(data[AMOUNT]),
                  attr_records: request.attr_records,
                },
              },
            },
            transaction_type: "authorization",
            payment_method: { type: "credit_card" },
          },
        }),
      };

      const getToken = await fetch(
        `${process.env.REACT_APP_WIDGET_URL}/ctp/api/checkouts`,
        reqObj
      );

      const {
        checkout: { token },
      } = await getToken.json();

      const params = {
        checkout_url: process.env.REACT_APP_WIDGET_URL,
        token,
        checkout: {
          public_key: process.env.REACT_APP_PUBLIC_KEY,
        },
        closeWidget: function () {
          handleBreadcrumbChange(
            {},
            breadcrumbs.at(-2),
            breadcrumbs.length - 2
          );
          setWidgetRequested(false);
          getTree({
            pay_code: lastBreadcrumb.code,
            di_type: lastBreadcrumb.di_type,
          });
        },
      };

      setWidgetRequested(true);
      //@ts-ignore
      new BeGateway(params).createWidget();
    }
  };

  return (
    <Section className={className}>
      <Container>
        <LazyMotion features={domAnimation}>
          {Boolean(breadcrumbs?.length) && (
            <Breadcrumbs
              items={breadcrumbs}
              onClick={handleBreadcrumbChange}
              requestSent={requestSent}
            />
          )}
          {
            <m.div
              key={JSON.stringify(requestSent)}
              style={{
                width: "100%",
                height: "100%",
                flexGrow: 1,
                flexDirection: "column",
                display: "flex",
              }}
              {...MOTION_FADE_BACK_PROPS}
            >
              {requestSent ? (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              ) : (
                <>
                  {title && <Title variant="h2">{title}</Title>}
                  {form?.information && (
                    <FormInformation variant="h4">
                      {form.information}
                    </FormInformation>
                  )}
                  {formErrorMessage && (
                    <FormError variant="h5" style={{ fontWeight: "500" }}>
                      {formErrorMessage}
                    </FormError>
                  )}
                  {Boolean(tree?.length) && (
                    <Tree items={tree} onClick={handleTreeItemSelect} />
                  )}
                  {form && (
                    <Form
                      normalizedNames={normalizedNames}
                      widgetRequested={widgetRequested}
                      fields={form.attr_records}
                      defaultComissionRatio={form.commission}
                      defaultFine={form.fine}
                      buttonText={
                        form.get_pay_list_type === "0" ? "Подтвердить" : "Далее"
                      }
                      caption={
                        form.get_pay_list_type === "0" && (
                          <Terms>
                            <Typography variant="p">
                              Нажимая на кнопку Подтвердить, я подтверждаю, что
                              ознакомлен и согласен с{" "}
                              <a href="/doc/public.pdf" target="_blank">
                                условиями публичной оферты
                              </a>
                            </Typography>
                          </Terms>
                        )
                      }
                      onSubmit={handleSubmitForm}
                    />
                  )}
                </>
              )}
            </m.div>
          }
        </LazyMotion>
      </Container>
    </Section>
  );
};

export default Layout;
