import React, { HTMLInputTypeAttribute, ReactNode, useState } from "react";
import {
  Input as InputStyled,
  InputWrapper,
  InputLabel,
  InputHelper,
  InputError,
  Wrapper,
  LabelWrapper,
  ContentContainer,
  Content,
  InputIcon,
} from "./Input.styled";

interface IInput {
  type?: HTMLInputTypeAttribute;
  required?: boolean;
  errorMessage?: string;
  label?: string;
  placeholder?: string;
  helperText?: string;
  className?: string;
  onFocus?: (e: React.MouseEvent) => void;
  onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
  onChange?: (e: React.ChangeEvent) => void;
  value: string;
  name: string;
  inputIcon?: string | ReactNode;
}

const Input = ({
  type = "text",
  onBlur,
  onChange,
  required,
  errorMessage,
  helperText,
  label,
  placeholder,
  className,
  value,
  name,
  inputIcon,
}: IInput) => {
  const [focused, setFocused] = useState(false);
  const isFocused = !!(focused || value);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLElement>) => {
    setFocused(false);
    onBlur?.(e);
  };
  return (
    <>
      <Wrapper error={!!errorMessage}>
        <ContentContainer
          isFocused={focused}
          error={!!errorMessage}
          className={className}
        >
          {label && (
            <LabelWrapper isActive={isFocused}>
              <InputLabel
                error={!!errorMessage}
                isActive={isFocused}
                variant="p"
              >{`${label} ${required ? "*" : ""}`}</InputLabel>
            </LabelWrapper>
          )}
          <Content>
            <InputWrapper>
              <InputStyled
                type={type}
                placeholder={placeholder}
                required={required}
                onChange={onChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                value={value}
                name={name}
              />
              {inputIcon && <InputIcon>{inputIcon}</InputIcon>}
            </InputWrapper>
          </Content>
        </ContentContainer>
        {helperText && <InputHelper variant="p">{helperText}</InputHelper>}
        {errorMessage && <InputError variant="p">{errorMessage}</InputError>}
      </Wrapper>
    </>
  );
};

export default Input;
