import styled from "styled-components";
import Typography from "../Typography";

export const ContentContainer = styled("div")<{
  error: boolean;
  isFocused: boolean;
}>(({ theme, error, isFocused }) => ({
  borderRadius: "6px",
  overflow: "hidden",
  position: "relative",
  padding: '2px',
  backgroundColor: `${error
    ? `rgba(225, 15, 34, 0.2)`
    : isFocused
      ? theme.colors.text.white
      : theme.colors.text.softGray
    }`,
  "&:after": {
    content: '""',
    position: "absolute",
    zIndex: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: "inherit",
    border: "2px solid transparent",
    transition: "border-color 0.2s",
    borderColor: error
      ? "currentcolor"
      : isFocused
        ? theme.colors.text.blue
        : "transparent",
  },
}));

export const Wrapper = styled("div")<{
  error: boolean;
}>(({ theme, error }) => ({ color: error ? theme.colors.error : "inherit" }));

export const LabelWrapper = styled("div")<{ isActive: boolean }>(
  ({ isActive }) => ({
    padding: "0 12px",
    position: "absolute",
    top: 0,
    height: isActive ? "28px" : "56px",
    display: "inline-flex",
    alignItems: "center",
    transition: "0.05s",
    pointerEvents: 'none',
    zIndex: '2'
  })
);

export const Input = styled("input")(
  () => ({
    width: "100%",
    border: "none",
    backgroundColor: "transparent",
    outline: "none",
    padding: "20px 12px 7px",
    fontSize: "18px",
    fontWeight: 500,
    "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus": {
      transition: "background-color 0s 600000s, color 0s 600000s",
      "-webkit-text-fill-color": "inherit",
      "-webkit-box-shadow": "0 0 0px 1000px transparent inset",
    },
  })
);

export const InputWrapper = styled("div")(() => ({
  position: "relative",
  zIndex: "1",
  width: "100%",
  display: "flex",
  alignItems: "center",
}));

export const InputLabel = styled(Typography)<{
  isActive: boolean;
  error: boolean;
}>(({ theme, error, isActive }) => ({
  marginBottom: theme.spacing(0.5),
  color: error ? "inherit" : theme.colors.text.midGray,
  transition: "0.1s",
  fontSize: isActive ? "12px !important" : "18px !important",

  ":hover": {
    cursor: "text",
  },
}));

export const InputHelper = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
}));

export const InputError = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  fontWeight: 500,
}));

export const Content = styled("div")(({ theme }) => ({
  flex: 1,
  height: "100%",
  display: "flex",
  minHeight: "56px",
  alignItems: "flex-end",
}));

export const InputIcon = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  paddingRight: "14px",
  transform: "translateY(-4px)",
}));
