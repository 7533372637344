import React from "react";
import Typography from "../Typography";
import { Wrapper, Cards, Text, Container } from "./Footer.styled";

const brands = [
  "mastercard.svg",
  "mastercard-security.svg",
  "security-pci-dss.svg",
  "belkart.svg",
  "security-belkart.png",
];

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Text>
          <Typography variant="p">
            Сервис разработан и предоставляется платёжным сервисом{" "}
            <a href="https://bepaid.by" target="_blank" rel="noreferrer">
              bePaid®
            </a>{" "}
            и{" "}
            <a
              href="https://www.paritetbank.by"
              target="_blank"
              rel="noreferrer"
            >
              ОАО "Паритетбанк"
            </a>{" "}
          </Typography>
          <Typography variant="p">
            Условия{" "}
            <a href="/doc/public.pdf" target="_blank" rel="noreferrer">
              публичной оферты
            </a>
          </Typography>
        </Text>
        <Cards>
          {brands.map((b) => (
            <img key={b} src={`/footer/${b}`} loading="lazy" alt="" />
          ))}
        </Cards>
      </Container>
    </Wrapper>
  );
};

export default Footer;
