import React from "react";
import { Icon, Typography } from "../../shared";
import {
  Wrapper,
  Container,
  LogoContainer,
  Contacts,
  Schedule,
  ScheduleMin,
  Title,
} from "./Header.styled";

const Header = () => {
  return (
    <Wrapper>
      <Container>
        <LogoContainer href="/">
          <Icon type={"logo"} />
          <Title variant="h1">СЕРВИС ОПЛАТЫ УСЛУГ</Title>
        </LogoContainer>
        <Contacts>
          <Schedule>
            <Typography variant="p">Круглосуточная поддержка:&nbsp;</Typography>
          </Schedule>
          <ScheduleMin>
            <Typography variant="p">Поддержка 24/7</Typography>
          </ScheduleMin>
          <a href="mailto:help@bepaid.by">
            <Typography variant="p">help@bepaid.by</Typography>
          </a>
        </Contacts>
      </Container>
    </Wrapper>
  );
};

export default Header;
