import styled from "styled-components";
import { Button, Typography } from "../../shared";

export const Form = styled("form")(() => ({
  ".non-required": {
    pointerEvents: "none",
    opacity: "0.5",
  },
}));

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  "> *:not(:last-child)": {
    marginBottom: theme.spacing(3),
  },
}));

export const FormButton = styled(Button)(() => ({}));

export const FormError = styled(Typography)(({ theme }) => ({
  color: theme.colors.error,
}));

export const Buttons = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  maxWidth: "350px",
  gap: "20px",
}));
