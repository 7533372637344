import Container from "../../entities/Container";
import { Footer, Header } from "../../shared";
import { Transaction } from "./Result.styled";

const Result = () => {
  return (
    <Container>
      <Header />
      <Transaction />
      <Footer />
    </Container>
  );
};

export default Result;
