import React from "react";

interface ITypography {
  variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";
  children: React.ReactNode;
  className?: string;
  style?: { [key: string]: string | number };
  onClick?: (e: React.MouseEvent) => void;
}

const Typography = ({
  variant: Variant = "h1",
  children,
  className,
  onClick,
  style,
}: ITypography) => {
  return (
    <Variant className={className} onClick={onClick} style={style}>
      {children}
    </Variant>
  );
};

export default Typography;
