import styled from "styled-components";
import { Typography, Button as ButtonStyled } from "../../shared";
import { Link as LinkStyled } from "react-router-dom";

export const Section = styled("section")(({ theme }) => ({
  display: "flex",
  color: theme.colors.text.darkBlue,
  minHeight: "60vh",
}));

export const Content = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "0.6fr 0.3fr 0.1fr",
  gridGap: "24px",
  [theme.breakpoint.down.lg]: {
    gridTemplateColumns: "1fr",
  },
}));

export const ResultList = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "492px",
  [theme.breakpoint.down.lg]: {
    maxWidth: "100%",
  },
}));

export const ResultImg = styled("div")(({ theme }) => ({
  maxWidth: "308px",
  width: "100%",
  marginTop: theme.spacing(8),
}));

export const Status = styled("div")(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  margin: `0 auto ${theme.spacing(2)}`,
  img: {
    width: "100%",
    height: "100%",
  },
  [theme.breakpoint.down.lg]: {
    marginBottom: theme.spacing(1.5),
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Russo One",
  textAlign: "center",
  margin: `0 auto ${theme.spacing(7.5)}`,
  [theme.breakpoint.down.lg]: {
    marginBottom: theme.spacing(5),
  },
}));

export const ResultImgWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  position: "relative",
  [theme.breakpoint.down.lg]: {
    display: "none",
  },
  "&::after": {
    content: '""',
    display: "block",
    width: "100%",
    paddingBottom: "calc(268 / 308 * 100%)",
  },
  img: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
}));

export const Buttons = styled("div")<{ isSuccess?: boolean }>(
  ({ theme, isSuccess }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: isSuccess ? "flex-start" : "center",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "30px",
    marginTop: theme.spacing(3),
    [theme.breakpoint.down.lg]: {
      justifyContent: "center",
    },
  })
);

export const Button = styled(ButtonStyled)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  padding: "13px 24px",
  [theme.breakpoint.up.lg]: {
    maxWidth: "238px",
    padding: "19px 24px",
  },
}));
export const Link = styled(LinkStyled)(({ theme }) => ({
  fontWeight: "500",
  textAlign: "center",
  color: theme.colors.text.blue,
  "&:hover": {
    textDecoration: "underline",
  },
}));

export const BlockTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Russo One",
  margin: theme.spacing(0, 1.5),
  [theme.breakpoint.down.lg]: {
    margin: theme.spacing(0, 1),
  },
}));

export const BlockContent = styled("div")(({ theme }) => ({
  wordBreak: "break-word",
  margin: theme.spacing(0, 1.5),
  [theme.breakpoint.down.lg]: {
    margin: theme.spacing(0, 1),
  },
}));

export const Questions = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 1.5),
  [theme.breakpoint.down.lg]: {
    margin: theme.spacing(0, 1),
  },
}));

export const BlueLink = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "500",
}));

export const TimeoutedBlock = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
  gap: theme.spacing(3.75),
  marginBottom: theme.spacing(3.75),
  [theme.breakpoint.up.lg]: {
    marginTop: theme.spacing(11.75),
  },
}));
