import { styled } from "styled-components";
import { Dashboard as DashboardStyled } from "../../widgets";

export const Dashboard = styled(DashboardStyled)(({ theme }) => ({
  marginBottom: "auto",
  padding: theme.spacing(2, 6),
  [theme.breakpoint.down.lg]: {
    padding: theme.spacing(2, 3),
  },
}));
