import styled from "styled-components";
import { Typography } from "../../shared";

export const Breadcrumbs = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flexWrap: "wrap",
  gap: theme.spacing(1.25),
  color: theme.colors.text.secondary,
}));

export const Breadcrumb = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  cursor: "pointer",
  fontWeight: "normal",
  transition: "color 0.3s",
  "&:hover": {
    color: theme.colors.text.main,
  },
}));

export const Separator = styled("div")(({ theme }) => ({
  width: theme.spacing(1),
  height: theme.spacing(1),
  border: "2px solid currentColor",
  transform: "rotate(45deg)",
  borderLeft: "none",
  borderBottom: "none",
}));
