import Container from "../../entities/Container";
import { Footer, Header } from "../../shared";
import { Dashboard } from "./Home.styled";

const Home = () => {
  return (
    <Container>
      <Header />
      <Dashboard />
      <Footer />
    </Container>
  );
};

export default Home;
