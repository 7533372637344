import styled from "styled-components";
import { Typography } from "../../shared";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: theme.spacing(3),
  "@media (max-width: 800px)": {
    gridTemplateColumns: "1fr",
  },
}));

export const TreeItem = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1.25),
  cursor: "pointer",
  transition: "opacity 0.3s",
  "&:hover": {
    opacity: 0.8,
  },
}));

export const TreeText = styled(Typography)(({ theme }) => ({}));

export const IconWrapper = styled("div")(({ theme }) => ({
  flexShrink: 0,
  width: theme.spacing(3),
  height: theme.spacing(3),
}));
