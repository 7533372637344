import { createGlobalStyle } from "styled-components";

const DEFAULT_SPACING = 8;

const DOWN_LG = "@media (max-width: 991.98px)";
const UP_LG = "@media (min-width:992px)";

const DEFAULT_THEME_OPTIONS = {
  spacing: (...args: number[]) =>
    args.reduce(
      (acc: string, cur: number) => (acc += `${cur * DEFAULT_SPACING}px `),
      ""
    ),
  font: {
    main: `"IBM Plex Sans", sans-serif`,
  },
  breakpoint: {
    up: {
      lg: UP_LG,
    },
    down: {
      lg: DOWN_LG,
    },
  },
};

export const lightTheme = {
  colors: {
    body: "white",
    text: {
      main: "#343434",
      secondary: "#757575",
      brand: "#a47ef7",
      white: "#ffffff",
      softGray: "#eff2f7",
      midGray: "#6b778c",
      midWhite: "#f7f8fb",
      purple: "#a47ef7",
      darkPurple: "#6827f0",
      orange: "#e36f10",
      blue: "#0065ff",
      darkBlue: "#091E42",
      gradient: "linear-gradient(90deg, #2659F3 0%, #6827F0 100%)",
      paritetBrandColor: "#231F20",
    },
    error: "red",
  },
  ...DEFAULT_THEME_OPTIONS,
};

export const darkTheme = {
  colors: {
    body: "black",
    text: {
      main: "white",
      secondary: "gray",
      brand: "#e36f10",
    },
    error: "red",
  },
  ...DEFAULT_THEME_OPTIONS,
};

export const GlobalStyles = createGlobalStyle`
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }

    #root{
      min-height: inherit;
      display: flex;
      flex-direction: column;
    }

    a{
      color: inherit;
      text-decoration: none;
      transition: opacity 0.3s;
      
      &:hover{
        opacity: 0.8;
      }
    }
    

    body {
        background: ${({ theme }) => theme.colors.body};
        color: ${({ theme }) => theme.colors.text.darkBlue};
        min-height: 100vh;
        overflow-x: hidden;
        font-family: ${({ theme }) => theme.font.main};
        background-image: url('/bg/bg_1.svg'),
        url('/bg/bg_2.svg'),
        url('/bg/bg_3.svg');
        background-position: 0 0,0 100%,100% 100%;
        background-repeat: no-repeat;
        ${({ theme }) =>
          `${theme.breakpoint.down.lg} {background-image:none; }`}
    }

    h1 {
      font-size: 32px;
      line-height: 36px;

      ${({ theme }) =>
        `${theme.breakpoint.down.lg} {
            font-size: 22px;
            font-weight: 400;
            line-height: 28px;
          }`}
    }

    h2 {
      font-weight: normal;
      font-size: 24px;
      line-height: 130%;

      ${({ theme }) =>
        `${theme.breakpoint.down.lg} {
           font-size: 20px;
          }`}
    }

    h3 {
      font-weight: bold;
      font-size: 22px;
      line-height: 28px;

      ${({ theme }) =>
        `${theme.breakpoint.down.lg} {
            font-size: 18px;
            font-weight: 500;
            line-height: 18px;
          }`}
    }

    h4 { 
      font-size: 1.25rem; 
      line-height: 1.25; 
      font-weight: normal; 

      ${({ theme }) =>
        `${theme.breakpoint.down.lg} {
            font-size: 1.125rem; 
          }`}
    }

    h5 { 
      font-size: 1.125rem; 
      line-height: 1.25; 
      font-weight: normal; 

      ${({ theme }) =>
        `${theme.breakpoint.down.lg} {
            font-size: 18px;
            line-height: 27px;
          }`}
    }

    h6 {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;

      ${({ theme }) =>
        `${theme.breakpoint.down.lg} {
            font-size: 16px;
            line-height: 22px;
          }`}
    }
      
    p { 
      font-size: 14px; 
      line-height: 18px; 

      ${({ theme }) =>
        `${theme.breakpoint.down.lg} {
            
          }`}
    }

    span { 
      font-size: 12px; 
      line-height: 16px; 

      ${({ theme }) =>
        `${theme.breakpoint.down.lg} {
            
          }`}
    }

    input,
    label,
    select,
    button,
    textarea {
        margin:0;
        border:0;
        padding:0;
        display:inline-block;
        vertical-align:middle;
        white-space:normal;
        background:none;
        border-radius: 0;
        appearance: none;
        -webkit-appearance: none;
    }

    input:focus {
        outline:0;
    }
   
    input,
    textarea {
        -webkit-box-sizing:content-box;
        -moz-box-sizing:content-box;
        box-sizing:content-box;
    }

    button,
    input[type=reset],
    input[type=button],
    input[type=submit],
    input[type=checkbox],
    input[type=radio],
    select {
        -webkit-box-sizing:border-box;
        -moz-box-sizing:border-box;
        box-sizing:border-box;
    }


    input[type=checkbox],
    input[type=radio] {
        width:13px;
        height:13px;
    }

    input[type=search] {
        -webkit-appearance:textfield;
        -webkit-box-sizing:content-box;
    }

    ::-webkit-search-decoration {
        display:none;
    }

    button,
    input[type="reset"],
    input[type="button"],
    input[type="submit"] {
        overflow:visible;
        width:auto;
    }

    ::-webkit-file-upload-button {	
        padding:0;
        border:0;
        background:none;
    }

    textarea {
        vertical-align:top;
        overflow:auto;
    }
`;
