import styled from "styled-components";

export const Wrapper = styled("footer")(({ theme }) => ({
  color: theme.colors.text.midGray,
  width: "100%",
  background: theme.colors.text.midWhite,
  padding: theme.spacing(4, 6),
  margin: theme.spacing(6, 0, 3),

  [theme.breakpoint.up.lg]: {
    borderRadius: theme.spacing(1.5),
  },
  [theme.breakpoint.down.lg]: {
    padding: theme.spacing(4, 3, 6),
    margin: theme.spacing(6, 0, 0),
  },
  a: {
    textDecoration: "underline",
  },
}));

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoint.down.lg]: {
    flexDirection: "column",
  },
}));

export const Cards = styled("div")(({ theme }) => ({
  maxWidth: "162px",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  opacity: "0.4",
  flexWrap: "wrap",
  rowGap: theme.spacing(1.5),
  columnGap: theme.spacing(1),
  img: {
    maxHeight: "20px",
  },
  [theme.breakpoint.down.lg]: {
    maxWidth: "unset",
    justifyContent: "center",
    columnGap: theme.spacing(1.25),
    padding: theme.spacing(3.5, 0, 1.5),
  },
}));

export const Text = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
}));
