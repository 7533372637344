import React from "react";
import {
  Breadcrumbs as BreadcrumbsStyled,
  Breadcrumb,
  Separator,
} from "./Breadcrumbs.styled";

export interface IBreadcrumb {
  name: string;
  code: string;
  di_type: string;
}

interface IBreadcrumbs {
  className?: string;
  items: IBreadcrumb[];
  onClick: (e: React.MouseEvent, item: IBreadcrumb, index: number) => void;
}

const Breadcrumbs = ({ className, items, onClick }: IBreadcrumbs) => {
  return (
    <BreadcrumbsStyled className={className}>
      {items.map((item, index) => (
        <React.Fragment key={item.code}>
          <Breadcrumb
            variant="p"
            onClick={(e: React.MouseEvent) => {
              onClick(e, item, index);
            }}
          >
            {item.name}
          </Breadcrumb>
          {index !== items.length - 1 && <Separator />}
        </React.Fragment>
      ))}
    </BreadcrumbsStyled>
  );
};

export default Breadcrumbs;
