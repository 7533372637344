import { createGlobalStyle } from "styled-components";

import IBMPlexSansRegular from "./IBMPlexSans.woff2";
import IBMPlexSansMedium from "./IBMPlexSans-Medium.woff2";
import IBMPlexSansBold from "./IBMPlexSans-Bold.woff2";
import RussoOneRegular from "./RussoOne-Regular.ttf";

export default createGlobalStyle`
    @font-face {
        font-family: 'IBM Plex Sans';
        src: url(${IBMPlexSansRegular}) format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'IBM Plex Sans';
        src: url(${IBMPlexSansMedium}) format('woff2');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'IBM Plex Sans';
        src: url(${IBMPlexSansBold}) format('woff2');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Russo One';
        src: url(${RussoOneRegular}) format('truetype');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }
`;
