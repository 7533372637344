interface IIcon {
  type?: "folder" | "sheet" | "logo" | "clock";
}

const Icon = ({ type }: IIcon) => {
  switch (type) {
    case "logo":
      return (
        <svg
          width="93"
          height="31"
          viewBox="0 0 93 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.6151 13.3361C7.91497 13.3361 4.91742 10.3413 4.91742 6.64467C4.91742 2.99478 7.91497 0 11.6151 0C15.2683 0 18.2659 2.99478 18.2659 6.64467C18.2659 10.3413 15.2683 13.3361 11.6151 13.3361ZM11.6151 3.64989C9.92895 3.64989 8.57069 5.0069 8.57069 6.64467C8.57069 8.32923 9.92895 9.68624 11.6151 9.68624C13.2544 9.68624 14.6126 8.32923 14.6126 6.64467C14.6126 5.0069 13.2544 3.64989 11.6151 3.64989Z"
            fill="#231F20"
          />
          <path
            d="M5.71408 30.0414H0V11.5112H5.71408V30.0414Z"
            fill="#231F20"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.5009 24.0051C18.5009 25.0813 18.0793 26.2043 16.7211 26.7191C16.2059 26.9062 15.597 27.0466 14.8008 27.0466H14.4729V30.0414H12.5994V21.0571H14.9413C15.7843 21.0571 16.44 21.1507 17.0021 21.4314C17.9857 21.8994 18.5009 22.7884 18.5009 24.0051ZM16.5805 24.0051C16.5805 23.4903 16.3464 23.0224 15.9248 22.7884C15.6438 22.6013 15.316 22.5545 14.8944 22.5545H14.4729V25.5024H14.8944C15.3003 25.5024 15.6282 25.4401 15.878 25.3153C16.3464 25.1281 16.5805 24.6602 16.5805 24.0051Z"
            fill="#231F20"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.1844 21.0571L26.8377 30.0414H24.8705L23.9806 27.8421H20.9362L20.0932 30.0414H18.1729L21.6856 21.0571H23.1844ZM23.4186 26.3447L22.8565 24.8473C22.6692 24.1922 22.5287 23.7711 22.435 23.4903C22.3413 23.8179 22.2476 24.1922 21.9666 24.8473L21.4514 26.3447H23.4186Z"
            fill="#231F20"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.3478 27.4678L34.7998 30.0414H32.6921L31.4744 27.8889C31.0528 27.0466 30.725 26.7191 30.4908 26.6255H29.8351V30.0414H27.9616V21.0571H30.6313C31.4275 21.0571 32.0364 21.1507 32.5048 21.3846C33.5352 21.8526 33.9567 22.6948 33.9567 23.6775C33.9567 24.8473 33.2073 25.6428 32.2238 25.9704C32.4579 26.1576 32.8795 26.6255 33.3478 27.4678ZM32.0364 23.8647C32.0364 23.3499 31.8022 22.9756 31.3807 22.7416C31.1933 22.648 30.9592 22.5545 30.5376 22.5545H29.8351V25.1281H30.5376C30.9123 25.1281 31.1933 25.0813 31.4275 24.9409C31.7554 24.707 32.0364 24.3326 32.0364 23.8647Z"
            fill="#231F20"
          />
          <path
            d="M53.6276 22.648H56.2504V30.0414H58.1239V22.648H60.5126L60.9809 21.0571H53.6276V22.648Z"
            fill="#231F20"
          />
          <path
            d="M52.2228 22.648L52.738 21.0571H47.4923V30.0414H52.6912V28.4504H49.3658V26.2511H52.2228V24.707H49.3658V22.648H52.2228Z"
            fill="#231F20"
          />
          <path
            d="M36.017 30.0414H37.8904V21.0571H36.017V30.0414Z"
            fill="#231F20"
          />
          <path
            d="M39.108 22.648H41.7309V30.0414H43.6044V22.648H45.993L46.4614 21.0571H39.108V22.648Z"
            fill="#231F20"
          />
          <path
            d="M88.755 25.5024L92.4551 21.0571H91.2841L87.8651 25.1749H87.8182V21.0571H86.8347V30.0414H87.8182V25.9236H87.8651L91.4715 30.0414H92.7361L88.755 25.5024Z"
            fill="#231F20"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M67.5855 27.4678C67.5855 29.012 66.5083 30.0414 64.4943 30.0414H62.1524V21.0571H64.2601C66.1804 21.0571 67.0235 22.0865 67.0235 23.3499C67.0235 24.239 66.5551 24.9877 65.8057 25.2685V25.3153C66.8361 25.5024 67.5855 26.3447 67.5855 27.4678ZM63.1828 25.0345H64.1664C65.3373 25.0345 65.9931 24.4262 65.9931 23.4435C65.9931 22.5545 65.4778 21.8994 64.2133 21.8994H63.1828V25.0345ZM66.5083 27.5146C66.5083 26.5319 65.7589 25.8768 64.6348 25.8768H63.1828V29.1991H64.4943C65.712 29.1991 66.5083 28.7312 66.5083 27.5146Z"
            fill="#231F20"
          />
          <path
            d="M83.6031 26.7659C83.6031 27.4678 83.6031 27.8889 83.65 28.3568H83.6031C83.3221 27.8889 82.9006 27.2806 82.2917 26.5319L78.1701 21.0571H77.1865V30.0414H78.2169V24.1454C78.2169 23.4435 78.1701 23.0692 78.1701 22.6013C78.4979 23.0692 78.9194 23.6307 79.4815 24.3326L83.7905 30.0414H84.6335V21.0571H83.6031V26.7659Z"
            fill="#231F20"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M72.5029 21.0571L75.9219 30.0414H74.8447L73.9548 27.6081H70.3015L69.4585 30.0414H68.3812L71.8003 21.0571H72.5029ZM72.6902 24.1922C72.4092 23.3967 72.2687 22.9756 72.175 22.648H72.1282C72.0345 22.9756 71.894 23.3967 71.5661 24.1922L70.6294 26.7659H73.6269L72.6902 24.1922Z"
            fill="#231F20"
          />
        </svg>
      );

    case "folder":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <g fill="none" fillRule="evenodd">
            <g transform="translate(-44 -266) translate(20 125) translate(24 141)">
              <path d="M0 0H24V24H0z" />
              <path
                fill="currentColor"
                d="M9.154 5.636c-.552 0-1-.447-1-1V2.77c0-.425-.345-.769-.77-.769H3c-.552 0-1 .448-1 1v14c0 .552.448 1 1 1h18c.552 0 1-.448 1-1V6.636c0-.552-.448-1-1-1H9.154zm1-2.867C10.154 1.24 8.914 0 7.384 0H3C1.343 0 0 1.343 0 3v14c0 1.657 1.343 3 3 3h18c1.657 0 3-1.343 3-3V6.636c0-1.656-1.343-3-3-3H10.154V2.77z"
                transform="translate(0 2)"
              />
            </g>
          </g>
        </svg>
      );
    case "clock":
      return (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M24 12H21V27H33V24H24V12Z" fill="#091E42" />
          <path
            d="M48 21V9L44.7 12.3C40.8 4.8 33 0 24 0C10.8 0 0 10.8 0 24C0 37.2 10.8 48 24 48C31.2 48 37.8 44.7 42 39.6L37.5 35.7C34.2 39.6 29.4 42 24 42C14.1 42 6 33.9 6 24C6 14.1 14.1 6 24 6C31.2 6 37.5 10.5 40.5 16.5L36 21H48Z"
            fill="#091E42"
          />
        </svg>
      );
    case "sheet":
    default:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <g fill="none" fillRule="evenodd">
            <g transform="translate(-44 -554) translate(20 125) translate(24 141) translate(0 288)">
              <path d="M0 0H24V24H0z" />
              <g fill="currentColor">
                <path
                  fillRule="nonzero"
                  d="M0 1.917C0 .858.846 0 1.889 0h9.684l7.212 7.338V22.07c0 1.059-.846 1.917-1.889 1.917H1.89C.846 23.986 0 23.128 0 22.069V1.917zm10.79 0H1.889v20.152h15.007V8.131L10.79 1.917z"
                  transform="translate(2.5)"
                />
                <path
                  d="M11.333 7.666H17v1.917h-5.556c-1.104 0-2-.896-2-2V1.916h1.89v5.75z"
                  transform="translate(2.5)"
                />
                <path
                  fillRule="nonzero"
                  d="M4.944 13.959c-.552 0-1-.448-1-1s.448-1 1-1h9c.553 0 1 .448 1 1s-.447 1-1 1h-9zM4.944 17.959c-.552 0-1-.448-1-1s.448-1 1-1h9c.553 0 1 .448 1 1s-.447 1-1 1h-9z"
                  transform="translate(2.5)"
                />
              </g>
            </g>
          </g>
        </svg>
      );
  }
};

export default Icon;
