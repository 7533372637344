import React, { HTMLInputTypeAttribute, ReactNode, useEffect } from "react";
import {
  Wrapper,
  Form as FormStyled,
  FormButton,
  FormError,
  Buttons,
} from "./Form.styled";
import { useForm, FieldValues, Controller } from "react-hook-form";
import { Input, Typography } from "../../shared";
import {
  AMOUNT,
  TOTAL_AMOUNT,
  COMISSION,
  getComission,
  isRequiredField,
  toFixedTwo,
} from "../../widgets/Dashboard/Dashboard";

export interface IField {
  type: HTMLInputTypeAttribute;
  label: string;
  name: string;
  required?: boolean;
  placeholder?: string;
  helper?: string;
}

interface IForm {
  className?: string;
  fields?: any[];
  isRequestSent?: boolean;
  buttonText: string;
  responseErrorMessage?: string;
  onSubmit: (data: FieldValues) => void;
  widgetRequested?: boolean;
  normalizedNames: { [key: string]: { default: string; normalized: string } };
  caption?: string | ReactNode;
  defaultComissionRatio?: string;
  defaultFine?: string;
}

const Form = ({
  className,
  fields,
  onSubmit,
  isRequestSent,
  buttonText,
  responseErrorMessage,
  widgetRequested,
  normalizedNames,
  caption,
  defaultComissionRatio,
}: IForm) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ shouldUnregister: true, delayError: 100, mode: "onBlur" });

  const parseSum = (value: string) => {
    const number = (value || "0").replace(/[^\d.\d]/g, "");

    return toFixedTwo(+number);
  };

  const isMinSum = (value: string, min: number) => {
    return +parseSum(value) >= min || `Сумма должна быть не менее ${min}`;
  };

  const isMaxSum = (value: string, max: number) => {
    return +parseSum(value) <= max || `Сумма должна быть не более ${max}`;
  };

  const innerFieldContent = (input: any) => {
    const {
      name: eripName,
      type,
      placeholder,
      hint: helper,
      name: label,
      max_length,
      min_length,
      value,
      edit,
      code_out,
      badge,
      min,
      max,
      data_type,
    } = input;

    const fieldRequired = isRequiredField(input);

    const name = normalizedNames[eripName]?.normalized || eripName;
    const isNumber = ["I", "R"].includes(data_type);

    const errorMessage = errors[name]?.message as string;
    const defValue = (value && `${value.replace(",", ".")}`) || null;

    const controllerParams = {
      name,
      control,
      defaultValue:
        defValue && data_type === "R" ? toFixedTwo(+defValue) : defValue,
      rules: {
        required: {
          value: fieldRequired,
          message: "Это поле обязательно",
        },
        ...(min_length
          ? {
              minLength: {
                value: +min_length,
                message: `Количество символов не должно быть меньше ${min_length}`,
              },
            }
          : {}),
        ...(max_length
          ? {
              maxLength: {
                value: +max_length,
                message: `Количество символов не должно быть больше ${max_length}`,
              },
            }
          : {}),
        ...(name === AMOUNT && edit === "1"
          ? {
              validate: {
                isMin: (v: string) => isMinSum(v, min),
                isMaxSum: (v: string) => isMaxSum(v, max),
              },
            }
          : {}),
      },
    } as any;

    switch (type) {
      default:
        return (
          <Controller
            key={`${name}${code_out}`}
            {...controllerParams}
            render={({ field }) => {
              const { onChange } = field;
              return (
                <Input
                  {...field}
                  className={edit === "0" ? "non-required" : ""}
                  required={fieldRequired}
                  placeholder={placeholder}
                  type={"text"}
                  errorMessage={errorMessage}
                  helperText={helper}
                  label={label}
                  onChange={(e: any) => {
                    const value = e.target.value;
                    if (isNumber) {
                      const replacedValue =
                        data_type === "R"
                          ? value.replace(/[^0-9.]/g, "")
                          : value.replace(/\D/g, "");

                      onChange(replacedValue);
                    } else {
                      onChange(value);
                    }
                  }}
                  onBlur={(e: any) => {
                    const value = e.target.value;
                    if (data_type === "R") {
                      if (value || fieldRequired) {
                        setValue(name, parseSum(value));
                      }
                    }
                  }}
                  inputIcon={<Typography variant="h5">{badge}</Typography>}
                />
              );
            }}
          />
        );
    }
  };

  const handleAmountWatch = (value: string) => {
    const coms = getComission({
      comission: +(defaultComissionRatio || 0),
      sum: +value,
    });

    setValue(COMISSION, `${coms}`);

    setValue(TOTAL_AMOUNT, `${toFixedTwo(+value + +coms)}`);
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      switch (name) {
        case AMOUNT:
          handleAmountWatch(value[AMOUNT]);
          break;
      }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <FormStyled className={className}>
      <Wrapper>
        {fields?.map(innerFieldContent)}
        {responseErrorMessage && (
          <FormError variant="h6">{responseErrorMessage}</FormError>
        )}
        <Buttons>
          <FormButton
            type="submit"
            disabled={
              isRequestSent || !!responseErrorMessage || widgetRequested
            }
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();

              !responseErrorMessage && handleSubmit(onSubmit)();
            }}
          >
            <Typography variant="h5">{buttonText}</Typography>
          </FormButton>

          {caption}
        </Buttons>
      </Wrapper>
    </FormStyled>
  );
};

export default Form;
