import { Spacer as SpacerStyled } from "./Spacer.styled";

const Spacer = ({
  className,
  style,
}: {
  className?: string;
  style?: { [key: string]: string };
}) => {
  return <SpacerStyled className={className} style={style} />;
};

export default Spacer;
