import { ThemeProvider } from "styled-components";
import { GlobalStyles, lightTheme } from "./theme";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Home, Result } from "./pages";
import FontStyles from "./fonts/index";

import { Provider } from "react-redux";
import { store } from "./store";

const router = createBrowserRouter([
  {
    path: "/*",
    element: <Home />,
  },
  {
    path: "/result/:token",
    element: <Result />,
  },
  {
    path: "/fill-form/:service_no_erip/:account_number?",
    element: <Home />,
  },
]);

function App() {
  //TODO do we need dark theme?
  const theme = lightTheme;
  // window.matchMedia &&
  // window.matchMedia("(prefers-color-scheme: dark)").matches
  //   ? darkTheme
  //   : lightTheme;

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <FontStyles />
        <GlobalStyles />
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
