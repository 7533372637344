import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import {
  Buttons,
  Content,
  ResultImg,
  ResultImgWrapper,
  ResultList,
  Section,
  Status,
  Title,
  Button,
  Link,
  BlockTitle,
  BlockContent,
  Questions,
  BlueLink,
  TimeoutedBlock,
} from "./Transaction.styled";
import { useParams } from "react-router-dom";
import { m } from "framer-motion";
import { LazyMotion, domAnimation } from "framer-motion";
import { MOTION_FADE_BACK_PROPS } from "../Dashboard/Dashboard";
import { LoaderWrapper } from "../Dashboard/Dashboard.styled";
import { Divider, Icon, Loader, Spacer, Typography } from "../../shared";
import { addBranch } from "../../store/reducers/branch";
import { useAppDispatch } from "../../store/hooks";

interface ITransaction {
  className?: string;
}

const Transaction = ({ className }: ITransaction) => {
  const dispatch = useAppDispatch();
  let { token } = useParams();
  const [result, setResult] = useState<any>(null);
  const [requestSent, setRequestSent] = useState(false);
  const [transactionCard, setTransactionCard] = useState<any>(null);
  const isStrangerThings = ["undefined", "timeout"].includes(token || "");
  const isSuccess = !isStrangerThings && result?.status === "successful";

  const getInfo = async () => {
    setRequestSent(true);
    try {
      const request = await fetch(
        `${process.env.REACT_APP_WIDGET_URL}/ctp/api/checkouts/${token}`,
        {
          headers: {
            "X-Api-Version": "2",
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${process.env.REACT_APP_PUBLIC_KEY}`,
          },
        }
      );
      const data = await request.json();

      const {
        gateway_response: {
          authorization: {
            uid,
            amount,
            receipt_url,
            currency,
            status,
            rrn,
            bank_code,
            auth_code,
          },
        },
        payment_method: { created_at },
        message,
        order: {
          description,
          additional_data: {
            komplat: {
              pay_code,
              di_type,
              erip_session_id,
              payment_id,
              erip_transaction_id,
              account_number,
              komplat_payment_id,
              komplat_record_id,
              receipt,
              summa,
              pay_commission,
              fine,
            },
          },
        },
      } = data?.checkout || {};

      if (status === "successful") {
        try {
          const res = await fetch(
            `${process.env.REACT_APP_WIDGET_URL}/ctp/api/transactions/${uid}`,
            {
              headers: {
                "Cache-Control": "no-cache",
                "X-Api-Version": "2",
                Authorization: `Bearer ${process.env.REACT_APP_PUBLIC_KEY}`,
              },
            }
          );
          const {
            transaction: { credit_card },
          } = await res.json();

          setTransactionCard(credit_card);
        } catch (e) {}
      }

      const transaction = {
        amount: Number(amount / 100).toFixed(2),
        status: status || "failed",
        message:
          message === "Транзакция была отклонена по правилам безопасности."
            ? "Транзакция отклонена. Данная карта не обслуживается, или превышен лимит платежа."
            : message,
        uid,
        auth_code,
        bank_code,
        created_at,
        rrn,
        receipt_url,
        currency,
        date: format(new Date(created_at), "dd.MM.yyyy, hh:mm"),
        pay_code,
        di_type,
        erip_session_id,
        payment_id,
        erip_transaction_id,
        account_number,
        komplat_payment_id,
        komplat_record_id,
        receipt,
        description,
        summa,
        pay_commission,
        fine,
      };

      setResult(transaction);
    } catch (e) {
      console.log(e);
    }
    setRequestSent(false);
  };

  const normalizePdfUrl = (url: string) => {
    try {
      const urlObj = new URL(url);
      urlObj.pathname = `${urlObj.pathname}.pdf`;

      return urlObj.toString();
    } catch (e) {
      return url;
    }
  };

  useEffect(() => {
    if (!isStrangerThings) {
      getInfo();
    } else {
      setResult({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section className={className}>
      <LazyMotion features={domAnimation}>
        <m.div
          key={JSON.stringify(requestSent)}
          style={{
            width: "100%",
            height: "100%",
            flexGrow: 1,
            flexDirection: "column",
            display: "flex",
            minHeight: "inherit",
          }}
          {...MOTION_FADE_BACK_PROPS}
        >
          {requestSent || !result ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <Content>
              <ResultList>
                {!isStrangerThings && (
                  <>
                    <Status>
                      <img
                        src={
                          isSuccess
                            ? "/result/success.svg"
                            : "/result/error.svg"
                        }
                        loading="lazy"
                        alt="status-icon"
                      />
                    </Status>

                    <Title variant="h1">
                      {isSuccess ? "Успешно оплачено" : "Отказано"}
                    </Title>
                  </>
                )}
                {isSuccess ? (
                  <>
                    <BlockTitle variant="h3">Отправитель платежа</BlockTitle>
                    <Divider style={{ margin: "20px 0" }} />
                    <BlockContent>
                      {transactionCard && (
                        <Typography variant="h6">
                          Банковская карточка:{" "}
                          {`${transactionCard.brand.toUpperCase()} xxxx ${
                            transactionCard.last_4
                          }`}
                        </Typography>
                      )}
                      <Typography variant="h6">
                        Номер операции: {result.uid}
                      </Typography>
                      <Typography variant="h6">RRN: {result.rrn}</Typography>
                      <Typography variant="h6">
                        Код авторизации: {result.auth_code}
                      </Typography>
                    </BlockContent>
                    <Spacer style={{ margin: "14px 0" }} />
                    <BlockTitle variant="h3">ЕРИП платеж</BlockTitle>
                    <Divider style={{ margin: "20px 0" }} />
                    <BlockContent
                      dangerouslySetInnerHTML={{
                        __html: [
                          `Номер операции ЕРИП: ${result.erip_transaction_id}`,
                          ...(result.receipt || []),
                        ].join("<br>"),
                      }}
                    ></BlockContent>
                    <Divider style={{ margin: "20px 0" }} />
                    <BlockContent>
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        Сумма платежа в ЕРИП: {Number(result.summa).toFixed(2)}{" "}
                        {result.currency}
                      </Typography>
                      {+Number(result.fine) > 0 && (
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Оплаченная пеня: {Number(result.fine).toFixed(2)}{" "}
                          {result.currency}
                        </Typography>
                      )}
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        Сумма вознаграждения сервиса:{" "}
                        {Number(result.pay_commission).toFixed(2)}{" "}
                        {result.currency}
                      </Typography>
                    </BlockContent>
                    <Divider style={{ margin: "20px 0" }} />
                    <BlockTitle variant="h2">
                      Сумма всего: {result.amount} {result.currency}
                    </BlockTitle>
                  </>
                ) : !isStrangerThings ? (
                  <>
                    <BlockContent>
                      <Typography
                        variant="h6"
                        style={{ color: "#6B778C", marginBottom: "6px" }}
                      >
                        Причина отказа
                      </Typography>
                      <Typography variant="h3" style={{ fontWeight: "500" }}>
                        {result.message}
                      </Typography>
                    </BlockContent>
                    <Divider style={{ margin: "36px 0 28px" }} />
                    {(result.rrn || result.uid) && (
                      <>
                        <BlockContent>
                          {result.rrn && (
                            <Typography variant="h6">
                              RRN: {result.rrn}
                            </Typography>
                          )}
                          {result.uid && (
                            <Typography variant="h6">
                              Номер операции: {result.uid}
                            </Typography>
                          )}
                        </BlockContent>
                        <Divider style={{ margin: "20px 0" }} />
                      </>
                    )}
                  </>
                ) : (
                  <TimeoutedBlock>
                    <Typography variant="h5" style={{ fontWeight: 500 }}>
                      Время обработки запроса истекло
                    </Typography>
                    <Icon type="clock" />
                  </TimeoutedBlock>
                )}

                {!isSuccess && (
                  <Questions variant="h5">
                    Остались вопросы?<br></br> Обратитесь в нашу тех поддержку:
                    <a href="mailto:help@bepaid.by"> help@bepaid.by</a>
                  </Questions>
                )}
                <Buttons isSuccess={isSuccess}>
                  {isSuccess ? (
                    <>
                      <Button
                        target="_blank"
                        rel="noreferrer"
                        href={`${normalizePdfUrl(result?.receipt_url)}`}
                      >
                        Скачать PDF
                      </Button>
                      <Link to="/">
                        <BlueLink variant="p">Вернуться на главную</BlueLink>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Button
                        href="/"
                        onClick={() => {
                          !isStrangerThings &&
                            dispatch(
                              addBranch({
                                code: result.pay_code,
                                di_type: result.di_type,
                              })
                            );
                        }}
                      >
                        {isStrangerThings
                          ? "Продолжить"
                          : "Попробовать ещё раз"}
                      </Button>
                    </>
                  )}
                </Buttons>
              </ResultList>
              <ResultImg>
                <ResultImgWrapper>
                  <img
                    src={
                      isSuccess
                        ? "/result/success-character.svg"
                        : "/result/fail-character.svg"
                    }
                    loading="lazy"
                    alt="character"
                  />
                </ResultImgWrapper>
              </ResultImg>
            </Content>
          )}
        </m.div>
      </LazyMotion>
    </Section>
  );
};

export default Transaction;
