import styled from "styled-components";
import {
  Breadcrumbs as BreadcrumbsComponent,
  Tree as TreeComponent,
} from "../../features";
import { Typography } from "../../shared";

export const Section = styled("section")(({ theme }) => ({
  paddingTop: theme.spacing(3),
  display: "flex",
}));

export const Container = styled("div")(({ theme }) => ({
  background: theme.colors.body,
  maxWidth: theme.spacing(150),
  display: "flex",
  flexDirection: "column",
  width: "100%",
  minHeight: "60vh",
  height: "100%",
  boxShadow: "0 2px 10px rgba(0,0,0,.2)",
  borderRadius: theme.spacing(1),
  margin: "auto",
  padding: theme.spacing(2, 3),
}));

export const Breadcrumbs = styled(BreadcrumbsComponent)<{
  requestSent: boolean;
}>(({ theme, requestSent }) => ({
  padding: theme.spacing(1, 0),
  pointerEvents: requestSent ? "none" : "all",
}));

export const Tree = styled(TreeComponent)(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));

export const Title = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));

export const LoaderWrapper = styled("div")(() => ({
  display: "flex",
  width: "100%",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  margin: "auto",
}));

export const FormError = styled(Typography)(({ theme }) => ({
  color: theme.colors.error,
}));

export const FormInformation = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

export const Terms = styled("div")(({ theme }) => ({
  color: theme.colors.text.midGray,
  a: {
    color: theme.colors.text.blue,
    textDecoration: "underline",
  },
}));
