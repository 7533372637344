import { Divider as DividerStyled } from "./Divider.styled";

const Divider = ({
  className,
  style,
}: {
  className?: string;
  style?: { [key: string]: string };
}) => {
  return <DividerStyled className={className} style={style} />;
};

export default Divider;
