import React from "react";
import { Button as ButtonStyled, Link } from "./Button.styled";

interface IButton {
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
  href?: string;
  target?: "_blank" | "_self";
  rel?: string;
  onClick?: (e: React.MouseEvent) => void;
}

const Button = ({
  onClick,
  children,
  className,
  type,
  disabled,
  href,
  rel,
  target,
}: IButton) => {
  return href ? (
    <Link
      to={href}
      target={target}
      onClick={onClick}
      className={className}
      rel={rel}
    >
      {children}
    </Link>
  ) : (
    <ButtonStyled
      type={type}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </ButtonStyled>
  );
};

export default Button;
