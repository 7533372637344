import React from "react";
import { Icon } from "../../shared";
import { Wrapper, TreeItem, IconWrapper, TreeText } from "./Tree.styled";

export interface ITreeItem {
  title: string;
  code: string;
  type?: "folder" | "sheet";
}

interface IBreadcrumbs {
  className?: string;
  items: { name: string; code: string; di_type: string; type?: any }[];
  onClick: (e: React.MouseEvent, item: any) => void;
}

const Tree = ({ className, items, onClick }: IBreadcrumbs) => {
  return (
    <Wrapper className={className}>
      {items.map((item) => (
        <TreeItem
          key={item.code}
          onClick={(e: React.MouseEvent) => onClick(e, item)}
        >
          <IconWrapper>
            <Icon type={item?.type || "folder"} />
          </IconWrapper>
          <TreeText variant="h5">{item.name}</TreeText>
        </TreeItem>
      ))}
    </Wrapper>
  );
};

export default Tree;
