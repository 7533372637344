import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../";

export interface BranchState {
  code: string;
  di_type: string;
}

const initialState: BranchState = {
  code: "",
  di_type: "",
};

export const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    addBranch: (
      state,
      action: PayloadAction<{ code: string; di_type: string }>
    ) => {
      state = action.payload;
      return state;
    },
    clearBranch: (state) => {
      state = initialState;
    },
  },
});

export const { addBranch, clearBranch } = branchSlice.actions;

export const getBranch = (state: RootState) => state.branch;

export default branchSlice.reducer;
