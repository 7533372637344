import { styled } from "styled-components";
import { Transaction as TransactionStyled } from "../../widgets";

export const Transaction = styled(TransactionStyled)(({ theme }) => ({
  marginBottom: "auto",
  padding: theme.spacing(8, 6, 2),
  [theme.breakpoint.down.lg]: {
    padding: theme.spacing(4.5, 3, 2),
  },
}));
