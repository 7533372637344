import { styled } from "styled-components";

export const Container = styled("div")(() => ({
  maxWidth: "1096px",
  flexGrow: "1",
  width: "100%",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
}));
